import axios from "axios";
import React, { useEffect, useState } from "react";
import { BadgeToPrint } from "../badge/BadgeToPrint";
import { getDbHeader, toastError } from "../utils/util";
import MuPb from "../widgets/MuPb";
import { API_ENDPOINT, HEADER } from "./myconst";
import { useParams } from "react-router-dom";
const RegisrationQr = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const { linkCode } = useParams();

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-user?uid=${linkCode}`,
        getDbHeader()
      );
      setUser(data);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <MuPb />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "800px",
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      <img
        src={HEADER}
        style={{ display: "block", width: "100%", margin: "16px auto" }}
        alt="Header"
      />
      <p style={{ fontWeight: "bold", fontSize: "19px" }}>
        Thank you for registering!
      </p>
      <p>
        An email is sent to you confirming your registration for
        <strong>IMTEX 2025</strong>
      </p>
      <div id="badge-to-print">
        {user && <BadgeToPrint user={user} isPreview={true} />}
      </div>
      <p
        style={{ fontWeight: "bold", marginTop: "16px", marginBottom: "16px" }}
      >
        Please show your E-Badge at the Registration Counter to collect your
        Printed Badge.
      </p>
      <div
        style={{
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <p style={{ marginBottom: "8px" }}>
          Once you activate the link you can login and start using the IMTEX
          2025 online portal.
        </p>
        <p style={{ marginBottom: "8px" }}>
          Visitor Badge: You may use fast-track registration at the event, to do
          so please take the print copy of your badge from your profile, by
          using the Print Badge button.
        </p>
        <p style={{ marginBottom: "8px" }}>
          Advantages of Pre-registering on Expoplanner:
        </p>
        <ul>
          <li>Avoiding queues</li>
          <li>Safe & Secure entry to the venue</li>
          <li>Know the products displayed</li>
          <li>Search the booths from the floor plan</li>
          <li>Connect and schedule your meetings</li>
        </ul>
        <span style={{ backgroundColor: "rgb(255, 238, 0)" }}>
          You may also register for “International Seminar on Machining
          Technologies” being held on 24-25 January 2025, during the IMTEX 2025,
          at Conference - BIEC, Bangalore.
          <br />
          To Register kindly log on to{" "}
          <span
            style={{ color: "rgb(0, 0, 255)" }}
            data-mce-style="color: #0000ff;"
          >
            <strong>
              {" "}
              <a
                href="https://www.ismt.imtma.in/"
                style={{ color: "rgb(0, 0, 255)" }}
                data-mce-style="color: #0000ff;"
                target="_blank"
              >
                www.ismt.imtma.in
              </a>
            </strong>
          </span>
          or call Madan : +91 7899437625 (madan@imtma.in) or Vrunda : +91
          9886611007 (seminar@imtma.in )
        </span>{" "}
        <br /> <br />
        <p
          style={{ textAlign: "center", fontWeight: "bold", margin: "16px 0" }}
        >
          IMTEX Team
        </p>
        <p style={{ marginTop: "16px", color: "#666" }}>
          <b>Note:</b>If you haven't received our email, please check your spam
          or junk folder. Sometimes, emails might be mistakenly classified as
          spam.
        </p>
      </div>
    </div>
  );
};

export default RegisrationQr;
